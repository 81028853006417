import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { Provider } from "react-redux";
import { useState, useEffect } from "react";
import store from "../global/store";
import axios from "axios";

const TopDogsCaptains = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { amount } = useParams();

  const internal = "http://127.0.0.1:8080/api/top_dogs?amount=" + amount;
  const prod =
    "https://klopperij.onrender.com" + "/api/top_dogs?amount=" + amount;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(prod);
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { field: "0", headerName: "Name", width: 150 },
    { field: "1", headerName: "Amount chosen", align: "center" },
  ];

  const rows = data ? data[0] : [];
  const rows2 = data ? data[1] : [];

  if (loading) {
    return <Typography>Loading... {amount} top dogs</Typography>;
  }

  return (
    <Provider store={store}>
      <Box m="20px">
        <div
          style={{
            display: "flex",
            gap: "1rem",
            paddingBottom: "0.5rem",
          }}
        >
          <Avatar sx={{ color: colors.greenAccent[300] }}>{amount}</Avatar>
          <Header subtitle="Top Dogs Captains"></Header>
        </div>
        <Box
          m="40px 0 0 0"
          height="30vh"
          margin={-1.8}
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { border: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            density="compact"
            rows={rows}
            columns={columns}
            hideFooter={true}
          ></DataGrid>
        </Box>
      </Box>
      <Box m="20px">
        <Header subtitle="Top Dogs Players"></Header>
        <Box
          m="40px 0 0 0"
          height="30vh"
          margin={-1.8}
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { border: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            density="compact"
            rows={rows2}
            columns={columns}
            hideFooter={true}
          ></DataGrid>
        </Box>
      </Box>
    </Provider>
  );
};

export default TopDogsCaptains;
