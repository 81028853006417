import { useState, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { Navigate } from "react-router-dom";
import { useDispatch, Provider } from "react-redux";
import store from "./scenes/global/store";
import "./App.css";
import axios from "axios";

function LoadingScreen() {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState(true);

  const dispatch = useDispatch();

  const internal = "http://127.0.0.1:8080/api/matchups";
  const prod = "https://klopperij.onrender.com" + "/api/matchups";
  useEffect(() => {
    setLoading(true);
    axios.get(prod).then((res) => {
      dispatch({ type: "SET_DATA", payload: res.data });

      setLoading(false);
      // setMsg(res.data)
      setData(res.data);
    });
  }, []);

  return (
    <Provider store={store}>
      <div>
        {loading ? (
          <div className="loading-screen">
            <div className="title-screen">
              <p className="title">DE KLOPPERIJ</p>
              <p className="slogan">Fantasy Country Club</p>
            </div>

            <div className="sweet-loading">
              <ThreeCircles
                height="100"
                width="100"
                color="#00c39a"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
            </div>
          </div>
        ) : (
          // <div><p>{msg}</p></div>
          <Navigate to="/main" state={data} />
        )}
      </div>
    </Provider>
  );
}

export default LoadingScreen;
