import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import CurrentTournament from "../../scenes/current_tournament/index.jsx";
import Captains from "../../scenes/captains/index.jsx";
import Matchups from "../../scenes/matchups/index.jsx";
import ProgressCircle from "../../components/ProgressCircle";
import { Provider, useSelector } from "react-redux";
import Standings from "../standings";
import React, { useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const test_data = useSelector((state) => state.data);
  const tournament_name = `Matchups for: ${test_data[3].name}`;
  const dispatch = useDispatch();

  const internal = "http://127.0.0.1:8080/api/matchups";
  const prod = process.env.REACT_APP_API_URL + "/matchups";

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(internal); // Use the appropriate URL here
  //     dispatch({ type: "SET_DATA", payload: response.data });
  //   } catch (error) {
  //     // Handle errors as needed
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   // Execute fetchData when the component mounts
  //   fetchData();

  //   // Add an event listener for beforeunload (F5/refresh)
  //   const handleBeforeUnload = () => {
  //     fetchData(); // Refresh data when F5/refresh is pressed
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [dispatch]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={-4}
      >
        <Header title={tournament_name}></Header>

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display={{ xs: "", sm: "grid" }}
        gridTemplateColumns="repeat(13, 1fr)"
        gridAutoRows="89px"
        gap="8px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 9"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          display={{ xs: "none", sm: "inline" }}
          alignItems="center"
          justifyContent="center"
        >
          <Captains></Captains>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          display="inline"
          alignItems="center"
          justifyContent="center"
          // margin={}
        >
          <Standings></Standings>
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 9"
          gridRow="span 6"
          backgroundColor={colors.primary[400]}
          display="inline"
          alignItems="center"
          justifyContent="center"
        >
          <Matchups></Matchups>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 6"
          backgroundColor={colors.primary[400]}
          // display={{ xs: "none", sm: "block" }}
          alignItems="center"
          justifyContent="center"
        >
          <CurrentTournament></CurrentTournament>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
